<template>
    <modal :show="show" v-if=" isValid('CanAddAllowanceType') || isValid('CanEditAllowanceType') ">

        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title" id="myModalLabel"> {{ $t('AddAllowanceType.UpdateAllowanceType') }}</h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title" id="myModalLabel">{{ $t('AddAllowanceType.AddAllowanceType') }}</h5>

                        </div>
                        <div class="">
                            <div class="card-body ">
                                <div class="row ">

                                    <div v-if="english=='true'" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.allowanceType.name.$error}">
                                        <label class="text  font-weight-bolder"> {{ $t('AddAllowanceType.NameEnglish') }}: <span class="LabelColour"> *</span></label>
                                        <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.allowanceType.name.$model" type="text" />
                                    </div>
                                    <div v-if="isOtherLang()" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.allowanceType.nameArabic.$error}">
                                        <label class="text  font-weight-bolder">{{ $t('AddAllowanceType.NameArabic') }}: <span class="LabelColour"> *</span></label>
                                        <input class="form-control  " v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'"  v-model="$v.allowanceType.nameArabic.$model" type="text" />
                                    </div>

                                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.allowanceType.description.$error}">
                                        <label class="text  font-weight-bolder"> {{ $t('AddAllowanceType.Description') }}: </label>
                                        <textarea class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.allowanceType.description.$model" type="text" />
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label style="margin: 7px;">{{ $t('AddAllowanceType.Status') }}</label> <br />
                                        <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': allowanceType.isActive, 'bootstrap-switch-off': !allowanceType.isActive}" v-on:click="allowanceType.isActive = !allowanceType.isActive" style="width: 72px;">
                                            <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-check-2"></i>
                                                </span>
                                                <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-simple-remove"></i>
                                                </span>
                                                <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!loading">
                            <div class="modal-footer justify-content-right" v-if="type=='Edit' && isValid('CanEditAllowanceType')">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveAllowanceType" v-bind:disabled="$v.allowanceType.$invalid"> {{ $t('AddAllowanceType.Update') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddAllowanceType.Cancel') }}</button>
                            </div>
                            <div class="modal-footer justify-content-right" v-if="type!='Edit' && isValid('CanAddAllowanceType')">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveAllowanceType" v-bind:disabled="$v.allowanceType.$invalid"> {{ $t('AddAllowanceType.Save') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddAllowanceType.Cancel') }}</button>
                            </div>
                        </div>
                        <div v-else>
                            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';

    import { requiredIf, maxLength } from "vuelidate/lib/validators"
    export default {
        props: ['show', 'allowanceType', 'type'],
        mixins: [clickMixin],
        data: function () {
            return {
                render: 0,
                arabic: '',
                english: '',
            }
        },
        validations: {
            allowanceType: {
                name: {
                    maxLength: maxLength(50)
                },
                nameArabic: {
                    required: requiredIf((x) => {
                        if (x.name == '' || x.name == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(50)
                },

                description: {
                    maxLength: maxLength(200)
                }
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            SaveAllowanceType: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.post('/Payroll/SaveAllowanceType', this.allowanceType, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.isSuccess == true) {
                        
                        if (root.type != "Edit") {
                            root.$store.state.allowanceTypeList.push({
                                id: response.data.allowanceType.id,
                                name: response.data.allowanceType.name,
                                nameArabic: response.data.allowanceType.nameArabic,
                                description: response.data.allowanceType.description,
                                isActive: response.data.allowanceType.isActive,
                            })
                            root.$swal({
                                title: "Saved!",
                                text: "Saved Successfully!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            
                            root.close();
                        }
                        else {
                            var data = root.$store.state.allowanceTypeList.find(function (x) {
                                return x.id == response.data.allowanceType.id;
                            });
                            data.id = response.data.allowanceType.id;
                            data.name = response.data.allowanceType.name;
                            data.nameArabic = response.data.allowanceType.nameArabic;
                            data.description = response.data.allowanceType.description;
                            data.isActive = response.data.allowanceType.isActive;
                            root.$swal({
                                title: "Saved!",
                                text: "Update Sucessfully!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: "Error!",
                            text: "Your Allowance Type Name  Already Exist!",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                });
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.allowanceType.id == '00000000-0000-0000-0000-000000000000' || this.allowanceType.id == undefined || this.allowanceType.id == '')
                this.GetAutoCodeGenerator();

        }
    }
</script>
