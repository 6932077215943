<template>
    <div class="row " v-if="isValid('CanViewBank')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="card " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                <div class="BorderBottom ml-4 mr-4 mt-3 mb-3">
                    <span class=" DayHeading">{{ $t('Bank.ListOfBank') }}</span>
                </div>


                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>{{ $t('Bank.SearchbyBrand') }}</label>
                                    <div>
                                        <input type="text" class="form-control search_input" v-model="searchQuery" name="search" id="search" :placeholder="$t('Search')" />
                                        <span class="fas fa-search search_icon"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" style="margin-top:18px;">
                                <a v-if="isValid('CanAddBank')" href="javascript:void(0)" class="btn btn-primary" v-on:click="AddBank"><i class="fa fa-plus"></i>  {{ $t('Bank.AddNew') }}</a>
                                <router-link :to="'/FinancialSetup'">
                                    <a href="javascript:void(0)" class="btn btn-outline-danger ">  <i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                                </router-link>
                            </div>

                        </div>

                        <div class="mt-2">
                            <div class=" table-responsive">
                                <table class="table table-striped table-hover table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead class="m-0">
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{ $t('Bank.CODE') }}
                                            </th>
                                            <th v-if="english=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{ $t('Bank.BANKNAME')  |englishLanguage }}
                                            </th>
                                            <th v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{ $t('Bank.BANKNAME') |arabicLanguage }}
                                            </th>
                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{ $t('Bank.IBNNUMBER') }}
                                            </th>
                                            <th class="text-center">
                                                {{ $t('Bank.Currency') }}
                                            </th>
                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{ $t('Sale.Type') }}
                                            </th>
                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{ $t('Bank.Reference') }}
                                            </th>


                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{ $t('Bank.Status') }}
                                            </th>
                                            <th class="text-center" v-if="isValid('CanAddCheque')">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(details,index) in resultQuery" v-bind:key="details.id">
                                            <td>
                                                {{index+1}}
                                            </td>
                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-if="isValid('CanEditBank')">
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="EditBankInfo(details.id)">{{details.code}}</a>
                                                </strong>
                                            </td>
                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-else>
                                                {{details.code}}
                                            </td>
                                            <td v-if="english=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{details.bankName}}</td>
                                            <td v-if="english=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{details.nameArabic}}</td>
                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{details.ibnNumber}}</td>
                                            <td class="text-center">{{details.currencyName}}</td>
                                            <td class="text-center">{{details.accounType}}</td>
                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{details.reference}}</td>


                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{details.active==true?$t('Bank.Active'):$t('Bank.De-Active')}}</td>

                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-if="isValid('CanAddCheque')"> <a href="javascript:void(0)" class="btn btn-primary btn-sm" v-on:click="openmodel(details.id)">  {{ $t('ChequeBook.Cheque') }}</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <chequelistmodel 
                                 :show="show"
                                 :bankId="bankId"
                                 v-if="show"
                                 @close="show=false" />
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'

    export default {
        mixins: [clickMixin],
        name: 'bank',
        data: function () {
            return {
                show: false,
                bankId:'',
                arabic: '',
                english: '',
                searchQuery: '',

                type: '',
                banklist: [

                ]
            }
        },
        computed: {
            resultQuery: function () {

                var root = this;

                if (this.searchQuery) {

                    return this.banklist.filter((cur) => {
                        return root.searchQuery.toLowerCase().split(' ').every(v => cur.bankName.toLowerCase().includes(v) || cur.nameArabic.toLowerCase().includes(v) || cur.code.toLowerCase().includes(v))
                    })
                } else {
                    return root.banklist;
                }
            },
        },

        methods: {
            openmodel: function (id) {

                this.show = !this.show;
                this.bankId = id;
            },
            GetBankData: function () {
                var root = this;
                var url = '/Accounting/BankList';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {


                        root.$store.dispatch('GetBankList', response.data.banks);
                        root.banklist = response.data.banks;
                    }
                });
            },
            AddBank: function () {
                this.$router.push('/AddBank')
            },
            EditBankInfo: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Accounting/BankDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.$router.push({
                            path: '/AddBank',
                            query: {
                                data: response.data
                            }
                        })
                    }
                });
            },
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.GetBankData();
        }
    }
</script>