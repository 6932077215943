import { render, staticRenderFns } from "./DayStart.vue?vue&type=template&id=6bbf7a74&scoped=true&"
import script from "./DayStart.vue?vue&type=script&lang=js&"
export * from "./DayStart.vue?vue&type=script&lang=js&"
import style0 from "./DayStart.vue?vue&type=style&index=0&id=6bbf7a74&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bbf7a74",
  null
  
)

export default component.exports