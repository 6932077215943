<template>
    <div class="row">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-header " style="padding-left:50px; padding-right:50px">
                    <h4 v-bind:class="$i18n.locale == 'ar' ? 'text-right' : ''"><strong>{{ $t('TermAndCondition.TermsCondition') }}</strong></h4>
                </div>
                <div class="card-body " style="padding-left:50px; padding-right:50px">
                    <p v-if="($i18n.locale == 'en' ||isLeftToRight())">

                        By using the {{applicationName}} apps, these terms will
                        automatically apply to you.you should make sure therefore
                        that you read them carefully before using the application. You are
                        not allowed to copy, or modify the application, any part of the application,
                        or our trademarks in any way. You are not allowed to attempt
                        to extract the source code of the application, and you also
                        should not try to translate the application into other languages, or
                        make derivative versions. The application itself, and all the trade
                        marks, copyright, database rights and other intellectual
                        property rights related to it, still belong to
                        {{companyName}}.
                    </p>
                    <p v-else class="arabicLanguage">
                        باستخدام تطبيقات نوبل ، ستنطبق هذه الشروط عليك تلقائيًا ، لذا يجب عليك التأكد من قراءتها بعناية قبل استخدام التطبيق. لا يُسمح لك بنسخ أو تعديل التطبيق أو أي جزء من التطبيق أو علاماتنا التجارية بأي شكل من الأشكال. لا يُسمح لك بمحاولة استخراج الكود المصدري للتطبيق ، ويجب أيضًا ألا تحاول ترجمة التطبيق إلى لغات أخرى ، أو عمل إصدارات مشتقة. لا يزال التطبيق نفسه وجميع العلامات التجارية وحقوق النشر وحقوق قواعد البيانات وحقوق الملكية الفكرية الأخرى المتعلقة به مملوكة لشركة {{companyName}}. المحدودة.
                    </p>
                    <p v-if="($i18n.locale == 'en' ||isLeftToRight())">
                        {{companyName}} is committed to ensuring that the application
                        is as useful and efficient as possible. For that reason, we
                        reserve the right to make changes to the application or to charge
                        for its services, at any time and for any reason. We will
                        never charge you for the application or its services without making
                        it very clear to you exactly what you are paying for.
                    </p>
                    <p v-else class="arabicLanguage">
                        {{companyName}} الجندي. المحدودة ملتزمة بضمان أن يكون التطبيق مفيدًا وفعالًا قدر الإمكان. لهذا السبب ، نحتفظ بالحق في إجراء تغييرات على التطبيق أو فرض رسوم على خدماته في أي وقت ولأي سبب. لن نفرض عليك أي رسوم مقابل التطبيق أو خدماته دون أن نوضح لك بالضبط ما تدفعه مقابله.

                    </p>



                    <p v-if="($i18n.locale == 'en' ||isLeftToRight())">
                        The {{applicationName}} apps stores and processes VAT, Employee data and shares this data with your parent
                        company who has created your account. It is your responsibility
                        to keep your username and password secure. Do not share your
                        credentials with anyone. By accepting these terms
                        and conditions, you also accept the use of required cookies. Our application
                        may need to collect your IP Address, your browser information for
                        security and fraud prevention.
                    </p>
                    <p v-else class="arabicLanguage">
                        تخزن تطبيقات نوبل وتعالج ضريبة القيمة المضافة وبيانات الموظفين وتشارك هذه البيانات مع الشركة الأم التي أنشأت حسابك. تقع على عاتقك مسؤولية الحفاظ على أمان اسم المستخدم وكلمة المرور الخاصين بك. لا تشارك بيانات الاعتماد الخاصة بك مع أي شخص. بقبولك لهذه الشروط والأحكام ، فإنك تقبل أيضًا استخدام ملفات تعريف الارتباط المطلوبة. قد يحتاج تطبيقنا إلى جمع عنوان IP الخاص بك ومعلومات متصفحك للأمان ومنع الاحتيال.
                    </p>
                    <p v-if="($i18n.locale == 'en' ||isLeftToRight())">
                        You should be aware that there are certain things that
                        {{companyName}} will not take responsibility for.
                        To function, this application will require you to have an
                        active internet connection. The connection can be Wi-Fi, or
                        provided by your mobile network provider, but
                        {{companyName}} cannot take responsibility for the
                        application not working at full functionality if you do not have
                        access to Wi-Fi, and you do not have any of your data
                        allowance left.
                    </p>
                    <p v-else class="arabicLanguage">
                        يجب أن تدرك أن هناك بعض الأشياء التي يقوم {{companyName}}. المحدودة لن تتحمل المسؤولية عن. لكي يعمل ، سيطلب منك هذا التطبيق أن يكون لديك اتصال إنترنت نشط. يمكن أن يكون الاتصال عبر Wi-Fi أو مقدمًا من مزود شبكة الهاتف المحمول الخاص بك ، ولكن {{companyName}}. لا تتحمل  المسؤولية عن التطبيق الذي لا يعمل بكامل وظائفه إذا لم يكن لديك وصول إلى شبكة Wi-Fi ، ولم يتبق لديك أي من البيانات المسموح بها.
                    </p>
                    <p></p>

                    <p v-if="($i18n.locale == 'en' ||isLeftToRight())">
                        With respect to {{companyName}} responsibility for
                        your use of the application, when you are using the application, it is
                        important to bear in mind that although we endeavour to
                        ensure that it is updated and correct at all times, we do
                        rely on third parties to provide information to us so that
                        we can make it available to you.
                        {{companyName}} accepts no liability for any loss,
                        direct or indirect, you experience as a result of relying
                        wholly on this functionality of the application.
                    </p>
                    <p v-else class="arabicLanguage">
                        فيما يتعلق بـ {{companyName}}. مسؤولية  عن استخدامك للتطبيق ، عند استخدام التطبيق ، من المهم أن تضع في اعتبارك أنه على الرغم من أننا نسعى لضمان تحديثه وصحته في جميع الأوقات ، فإننا نعتمد على أطراف ثالثة لتقديم المعلومات إلينا حتى نتمكن من إتاحته لك. {{companyName}} الجندي.  لا تتحمل أي مسؤولية عن أي خسارة ، مباشرة أو غير مباشرة ، تتعرض لها نتيجة الاعتماد كليًا على وظيفة التطبيق هذه.
                    </p>
                    <p v-if="($i18n.locale == 'en' ||isLeftToRight())">
                        From time to time, we will be updating the application with
                        latest version to improve your experience. The application is
                        currently available on Web  the requirements for
                        system  may change,
                        and you will be informed of any changes we will be making to the
                        application. {{companyName}} does not promise that
                        it will always update the application. However, you promise to always
                        accept updates to the application when offered to you, We
                        may also wish to stop providing the application, and may terminate
                        use of it at any time without giving notice of termination
                        to you. Unless we tell you otherwise, upon any termination,
                        (a) the rights and licenses granted to you in these terms
                        will end; (b) you must stop using the application.
                    </p>
                    <p v-else class="arabicLanguage">
                        من وقت لآخر ، سنقوم بتحديث التطبيق بأحدث إصدار لتحسين تجربتك. التطبيق متاح حاليًا على الويب وقد تتغير متطلبات النظام ، وسيتم إبلاغك بأي تغييرات سنجريها على التطبيق. {{companyName}} الجندي. لا تتعهد  بأنها ستقوم دائمًا بتحديث التطبيق. ومع ذلك ، فإنك تتعهد بقبول تحديثات التطبيق دائمًا عند عرضها عليك ، وقد نرغب أيضًا في التوقف عن تقديم التطبيق ، وقد ننهي استخدامه في أي وقت دون إشعار الإنهاء لك. ما لم نخبرك بخلاف ذلك ، عند أي إنهاء ، (أ) تنتهي الحقوق والتراخيص الممنوحة لك في هذه الشروط ؛ (ب) يجب عليك التوقف عن استخدام التطبيق.
                    </p>
                    <p v-if="($i18n.locale == 'en' ||isLeftToRight())"><strong>Changes to these Terms and Conditions</strong></p>
                    <p v-else class="arabicLanguage"><strong>التغييرات على هذه الشروط والأحكام</strong></p>
                    <p v-if="($i18n.locale == 'en' ||isLeftToRight())">
                        We may update our Terms and Conditions
                        from time to time. Whenever, we make a change, we will
                        notify you of any changes by posting the new Terms and
                        Conditions on this page. These changes are effective
                        immediately after they are posted on this page.
                    </p>
                    <p v-else class="arabicLanguage">
                        قد نقوم بتحديث الشروط والأحكام الخاصة بنا من وقت لآخر. عندما نقوم بإجراء تغيير ، سنخطرك بأي تغييرات عن طريق نشر الشروط والأحكام الجديدة على هذه الصفحة. تسري هذه التغييرات فور نشرها على هذه الصفحة.
                    </p>

                    <p v-if="($i18n.locale == 'en' ||isLeftToRight())"><strong>Contact Us</strong></p>
                    <p v-else class="arabicLanguage">
                        <strong>
                            اتصل بنا
                        </strong>
                    </p>
                    <p v-if="($i18n.locale == 'en' ||isLeftToRight())">
                        If you have any questions or suggestions about
                        our Terms and Conditions, do not hesitate to
                        contact us at Connect@{{companyName}}.com
                    </p>
                    <p v-else class="arabicLanguage">

                        إذا كان لديك أي أسئلة أو اقتراحات حول الشروط والأحكام الخاصة بنا ، فلا تتردد في الاتصال بنا على Connect@{{applicationName}}.com
                    </p>
                </div>
                <div class="card-footer text-center" v-if="!loading">
                    <button type="button" class="btn btn-primary  " v-bind:class="$i18n.locale == 'ar' ? 'arabicLanguage' : ''" v-on:click="TermAndCondition"> {{ $t('TermAndCondition.IAgreeCondition') }}</button>
                </div>
                <div class="card-footer col-md-3" v-else>
                    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                </div>
            </div>
        </div>


    </div>


</template>
<script>
    import Loading from 'vue-loading-overlay';

    export default {
        components: {
            Loading
        },

        data: function () {

            return {
                loading: false,
                companyId: '',
                companyName:'',
                applicationName:'',
                termsCondition: false
            }
        },

        methods: {


            TermAndCondition: function () {

                if (this.termsCondition == false) {
                    var token = '';
                    var root = this;
                    if (this.$session.exists()) {
                        token = localStorage.getItem('token');
                    }
                    root.loading = true;
                    root.$https.get('/Company/TermsAndConditionAgreed?companyId=' + root.companyId + '&termsCondition=' + true, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            if (response.data == true) {

                                root.loading = false;

                                localStorage.setItem('TermsCondition', true);
                                root.$router.push('/Setup');
                                root.$router.go();
                            }

                        });


                }
                else {
                    this.$router.push('/Setup');
                }


            },

        },
        mounted: function () {


            var terms = localStorage.getItem('TermsCondition');
            this.companyId = localStorage.getItem('CompanyID');
            this.companyName = localStorage.getItem('CompanyName')
            this.applicationName = localStorage.getItem('ApplicationName')

            if (terms == 'true') {
                this.termsCondition = true;
            }

        }
    }

</script>