<template>
    <div class="row " v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')" v-if="isValid('CanAddEmployeeSalary') || isValid('CanEditEmployeeSalary')">
        <div class="col-md-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card">
                <div>
                    <div class="card-header">
                        <h5 class="modal-title DayHeading " id="myModalLabel" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{ $t('AddEmployeeSalary.SalaryDetails') }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 form-group">
                                <label class="text  font-weight-bolder">{{ $t('AddEmployeeSalary.SalaryType') }} :<span class="LabelColour"> *</span></label>
                                <multiselect :options="salaryTypeOptions" v-model="salaryTemplate.salaryType" @input="GetPeriod()" :show-labels="false" v-bind:placeholder="$t('AddEmployeeSalary.SelectType')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                </multiselect>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 form-group">
                                <label class="text  font-weight-bolder">{{ $t('AddEmployeeSalary.PayPeriod') }} :<span class="LabelColour"> *</span></label>
                                <payroll-schedule-dropdown v-model="salaryTemplate.payPeriodId" :isEmployee="true" @input="GetpayPeriod" :key="periodRender" v-bind:values="salaryTemplate.payPeriodId" :salaryType="salaryTemplate.salaryType" />
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 form-group">
                                <label>{{ $t('AddEmployeeSalary.Employee') }}:<span class="LabelColour"> *</span></label>
                                <employeeDropdown v-model="salaryTemplate.employeeId" v-bind:values="salaryTemplate.employeeId" />
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 form-group">
                                <label class="text  font-weight-bolder">{{ $t('AddEmployeeSalary.BaseSalary') }} </label>
                                <input class="form-control" @focus="$event.target.select()" @keyup="updateBaseSalary()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="salaryTemplate.baseSalary" type="number" />
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 form-group" v-if="salaryTemplate.salaryType=='Hourly Based' || salaryTemplate.salaryType=='بالساعة'">
                                <label class="text  font-weight-bolder">{{ $t('AddEmployeeSalary.WeekdayHourlySalary') }} </label>
                                <input class="form-control" @focus="$event.target.select()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="salaryTemplate.weekdayHourlySalary" type="number" />
                            </div>

                            <!--<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 form-group" v-if="salaryTemplate.salaryType=='Hourly Based'">
                                <label class="text  font-weight-bolder">Weekend Day Hourly Salary</label>
                                <input class="form-control" @focus="$event.target.select()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="salaryTemplate.weekendDayHourlySalary" type="number" />
                            </div>-->

                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 form-group">
                                <label class="text  font-weight-bolder ">{{ $t('AddEmployeeSalary.SalaryTemplate') }}</label>
                                <salary-template-dropdown v-model="salaryTemplate.salaryTemplateId" v-on:input="GetTemplate(salaryTemplate.salaryTemplateId)" v-bind:values="salaryTemplate.salaryTemplateId" />
                            </div>


                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 form-group">
                                <div class="form-group">
                                    <label>{{ $t('AddEmployeeSalary.StartingDate') }}</label>
                                    <datepicker v-model="salaryTemplate.startingDate" />
                                </div>
                            </div>


                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 form-group">
                                <label class="text  font-weight-bolder">{{ $t('AddEmployeeSalary.GosiRate') }} </label>
                                <input class="form-control" @focus="$event.target.select()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="salaryTemplate.gosiRate" type="number" />
                            </div>

                        </div>


                        <div class="row mt-4">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h6 class="text  font-weight-bolder ">{{ $t('AddEmployeeSalary.AddAllowance') }} :<span class="LabelColour"> *</span></h6>
                                <allowanceDropdown @input="addProduct(allowanceId.id,allowanceId,'allowance')" v-model="allowanceId" />
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <table class="table add_table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <tr>
                                            <th style="width:5%;">#</th>

                                            <th style="width:20%;" v-if="english=='true'">
                                                {{ $t('AddEmployeeSalary.AllowanceNameEnglish') }}
                                            </th>
                                            <th style="width:20%;" v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{ $t('AddEmployeeSalary.AllowanceNameArabic') }}
                                            </th>
                                            <th style="width:20%;" class="text-center">
                                                {{ $t('AddEmployeeSalary.Percentage') }}
                                            </th>
                                            <th style="width:20%;" class="text-center">
                                                {{ $t('AddEmployeeSalary.Amount') }}
                                            </th>
                                            <th style="width:10%;" class="text-center">
                                                {{ $t('AddEmployeeSalary.Taxable') }}
                                            </th>
                                            <th style="width:5%;" class="text-center">
                                                {{ $t('AddEmployeeSalary.Action') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <template v-for="(allowance,index) in salaryTemplate.salaryDetailList">
                                            <tr v-if="allowance.type=='allowance'" v-bind:key="index">
                                                <td>
                                                    {{index+1}}
                                                </td>

                                                <td v-if="english=='true'">
                                                    {{allowance.nameInPayslip}}
                                                </td>
                                                <td v-if="isOtherLang()">
                                                    {{allowance.nameInPayslipArabic}}
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding: 5px 15px 5px 5px;' : 'padding: 5px 5px 5px 15px;' "><i class="fa fa-percent"></i></span>
                                                        </div>
                                                        <input type="number" @focus="$event.target.select()" class="form-control amount_field" @keyup="updateLineTable(allowance.percent, allowance ,'percent')" v-model="allowance.percent" />
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding: 5px 15px 5px 5px;' : 'padding: 5px 5px 5px 15px;' "> <i>{{currency}}</i></span>
                                                        </div>
                                                        <input type="number" @focus="$event.target.select()" class="form-control amount_field" @keyup="updateLineTable(allowance.amount, allowance ,'amount')" v-model="allowance.amount" />
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <input type="checkbox" class="checkBoxHeight" v-model="allowance.taxPlan"
                                                           v-on:change="updateBaseSalary()">
                                                </td>
                                                <td class="text-center">
                                                    <a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon btn-round" v-on:click="removeItem(allowance.rowId)"><i class=" fa fa-trash"></i></a>
                                                </td>
                                            </tr>

                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h6 class="text  font-weight-bolder ">{{ $t('AddEmployeeSalary.AddDeduction') }}:<span class="LabelColour"> *</span></h6>
                                <deductionDropdown @input="addProduct(deductionId.id,deductionId,'deduction')" v-model="deductionId" />
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <table class="table add_table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <tr>
                                            <th style="width:5%;">#</th>

                                            <th style="width:20%;" v-if="english=='true'">
                                                {{ $t('AddEmployeeSalary.DeductionNameEnglish') }}
                                            </th>
                                            <th style="width:20%;" v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{ $t('AddEmployeeSalary.DeductionNameArabic') }}
                                            </th>
                                            <th style="width:20%;" class="text-center">
                                                {{ $t('AddEmployeeSalary.Percentage') }}
                                            </th>
                                            <th style="width:20%;" class="text-center">
                                                {{ $t('AddEmployeeSalary.Amount') }}
                                            </th>
                                            <th style="width:10%;" class="text-center">
                                                {{ $t('AddEmployeeSalary.Taxable') }}
                                            </th>
                                            <th style="width:5%;" class="text-center">
                                                {{ $t('AddEmployeeSalary.Action') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <template v-for="(deduction ,index) in salaryTemplate.salaryDetailList">
                                            <tr v-if="deduction.type==='deduction'" v-bind:key="index">
                                                <td>
                                                    {{index+1}}
                                                </td>
                                                <td v-if="english=='true'">
                                                    {{deduction.nameInPayslip}}
                                                </td>
                                                <td v-if="isOtherLang()">
                                                    {{deduction.nameInPayslipArabic}}
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding: 5px 15px 5px 5px;' : 'padding: 5px 5px 5px 15px;' "><i class="fa fa-percent"></i></span>
                                                        </div>
                                                        <input type="number" @focus="$event.target.select()" class="form-control amount_field" @keyup="updateLineTable(deduction.percent, deduction ,'percent')" v-model="deduction.percent" />
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding: 5px 15px 5px 5px;' : 'padding: 5px 5px 5px 15px;' "> <i>{{currency}}</i></span>
                                                        </div>
                                                        <input type="number" @focus="$event.target.select()" class="form-control amount_field" @keyup="updateLineTable(deduction.amount, deduction ,'amount')" v-model="deduction.amount" />
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <input type="checkbox" class="checkBoxHeight" v-model="deduction.taxPlan"
                                                           v-on:change="updateBaseSalary()">
                                                </td>
                                                <td class="text-center">
                                                    <a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon btn-round" v-on:click="removeItem(deduction.rowId)"><i class=" fa fa-trash"></i></a>
                                                </td>
                                            </tr>

                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div class="row mt-4">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h6 class="text  font-weight-bolder ">{{ $t('AddEmployeeSalary.AddContribution') }} :<span class="LabelColour"> *</span></h6>
                                <contributionDropdown @input="addProduct(contributionId.id,contributionId,'contribution')" v-model="contributionId" />
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <table class="table add_table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <tr>
                                            <th style="width:5%;">#</th>

                                            <th style="width:25%;" v-if="english=='true'">
                                                {{ $t('AddEmployeeSalary.ContributionNameEnglish') }}
                                            </th>
                                            <th style="width:25%;" v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{ $t('AddEmployeeSalary.ContributionNameArabic') }}
                                            </th>
                                            <th style="width:20%;" class="text-center">
                                                {{ $t('AddEmployeeSalary.Percentage') }}
                                            </th>
                                            <th style="width:20%;" class="text-center">
                                                {{ $t('AddEmployeeSalary.Amount') }}
                                            </th>

                                            <th style="width:5%;" class="text-center">
                                                {{ $t('AddEmployeeSalary.Action') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <template v-for="(contribution ,index) in salaryTemplate.salaryDetailList">
                                            <tr v-if="contribution.type=='contribution'" v-bind:key="index">
                                                <td>
                                                    {{index+1}}
                                                </td>
                                                <td v-if="english=='true'">
                                                    {{contribution.nameInPayslip}}
                                                </td>
                                                <td v-if="isOtherLang()">
                                                    {{contribution.nameInPayslipArabic}}
                                                </td>
                                                <td style="width:20%">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding: 5px 15px 5px 5px;' : 'padding: 5px 5px 5px 15px;' "><i class="fa fa-percent"></i> </span>
                                                        </div>
                                                        <input type="number" @focus="$event.target.select()" class="form-control amount_field" @keyup="updateLineTable(contribution.percent, contribution ,'percent')" v-model="contribution.percent" />
                                                    </div>
                                                </td>
                                                <td class="text-center" style="width:20%">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding: 5px 15px 5px 5px;' : 'padding: 5px 5px 5px 15px;' "><i>{{currency}}</i></span>
                                                        </div>
                                                        <input type="number" @focus="$event.target.select()" class="form-control amount_field" @keyup="updateLineTable(contribution.amount, contribution ,'amount')" v-model="contribution.amount" />
                                                    </div>
                                                </td>

                                                <td class="text-center">
                                                    <a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon btn-round" v-on:click="removeItem(contribution.rowId)"><i class=" fa fa-trash"></i></a>
                                                </td>
                                            </tr>

                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div class="row mt-4">
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <h6 class="text  font-weight-bolder ">{{ $t('AddEmployeeSalary.IncomeTax') }} :<span class="LabelColour"> *</span></h6>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right">
                                <toggle-button v-model="salaryTemplate.incomeTax" v-on:change="addIncomeTax()" class="pr-2 pl-2 pt-2" color="#3178F6" />
                            </div>
                        </div>

                        <div class="row mt-2" v-if="salaryTemplate.incomeTax">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <table class="table add_table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <tr>
                                            <th style="width:5%;">#</th>

                                            <th style="width:20%;" v-if="english=='true'">
                                                {{ $t('AddEmployeeSalary.DeductionNameEnglish')}}
                                            </th>
                                            <th style="width:20%;" v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{ $t('AddEmployeeSalary.DeductionNameArabic')}}
                                            </th>
                                            <th style="width:20%;" class="text-center">
                                                {{ $t('AddEmployeeSalary.Percentage') }}
                                            </th>
                                            <th style="width:20%;" class="text-center">
                                                {{ $t('AddEmployeeSalary.Amount') }}
                                            </th>
                                            <th style="width:10%;" class="text-center">
                                                {{ $t('AddEmployeeSalary.AutoManual') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <template v-for="(deduction ,index) in salaryTemplate.salaryDetailList">
                                            <tr v-if="deduction.type==='Income Tax'" v-bind:key="index">
                                                <td>
                                                    {{index+1}}
                                                </td>
                                                <td v-if="english=='true'">
                                                    {{deduction.nameInPayslip}}
                                                </td>
                                                <td v-if="isOtherLang()">
                                                    {{deduction.nameInPayslipArabic}}
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding: 5px 15px 5px 5px;' : 'padding: 5px 5px 5px 15px;' "><i class="fa fa-percent"></i></span>
                                                        </div>
                                                        <input type="number" @focus="$event.target.select()" disabled class="form-control amount_field" @keyup="updateLineTable(deduction.percent, deduction ,'percent')" v-model="deduction.percent" />
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding: 5px 15px 5px 5px;' : 'padding: 5px 5px 5px 15px;' "> <i>{{currency}}</i></span>
                                                        </div>
                                                        <input type="number" @focus="$event.target.select()" v-bind:disabled="salaryTemplate.autoIncomeTax && deduction.nameInPayslip=='Income Tax'" class="form-control amount_field" @keyup="updateLineTable(deduction.amount, deduction ,'amount')" v-model="deduction.amount" />
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <toggle-button v-on:change="updateBaseSalary()" v-model="salaryTemplate.autoIncomeTax" class="pr-2 pl-2 pt-2" color="#3178F6" />
                                                </td>
                                            </tr>

                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <hr />
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <h5 class="font-weight-bold">{{ $t('AddEmployeeSalary.FinalSalaryDetails') }}</h5>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <table class="table table-borderless">
                                    <tr>
                                        <td>{{ $t('AddEmployeeSalary.GrossSalary') }}</td>
                                        <td class="text-right">{{ parseFloat(summary.grossSalary).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('AddEmployeeSalary.TaxableSalary') }}</td>
                                        <td class="text-right">{{ parseFloat(summary.taxableSalary).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('AddEmployeeSalary.TaxPerPeriod') }}</td>
                                        <td class="text-right">{{ parseFloat(summary.taxPerPeriod).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-success font-weight-bold">{{ $t('AddEmployeeSalary.NetSalary') }}</td>
                                        <td class="text-right text-success font-weight-bold">{{summary.netSalary}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-sm-12 arabicLanguage" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <button type="button" class="btn btn-success" v-bind:disabled="$v.salaryTemplate.$invalid" v-on:click="SaveSalaryTemplate" v-if="salaryTemplate.id=='00000000-0000-0000-0000-000000000000' && isValid('CanAddEmployeeSalary')"><i class="far fa-save"></i> {{ $t('AddEmployeeSalary.Save') }} </button>
                                <button type="button" class="btn btn-success" v-bind:disabled="$v.salaryTemplate.$invalid" v-on:click="SaveSalaryTemplate" v-if="salaryTemplate.id!='00000000-0000-0000-0000-000000000000' && isValid('CanEditEmployeeSalary')"><i class="far fa-save"></i> {{ $t('AddEmployeeSalary.Update') }} </button>
                                <button type="button" class="btn btn-danger mr-1 ml-1" v-on:click="Close()">{{ $t('AddEmployeeSalary.Cancel') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { required } from 'vuelidate/lib/validators';
    import Multiselect from 'vue-multiselect';


    export default ({
        components: {
            Multiselect
        },
        mixins: [clickMixin],

        data: function () {
            return {
                currency: '',
                allowanceId: '',
                deductionId: '',
                contributionId: '',
                arabic: '',
                english: '',
                salaryTemplate: {
                    id: '00000000-0000-0000-0000-000000000000',
                    salaryType: '',
                    payPeriodId: '',
                    baseSalary: 0,
                    weekdayHourlySalary: 0,
                    weekendDayHourlySalary: 0,
                    salaryTemplateId: '',
                    employeeId: '',
                    startingDate: '',
                    autoIncomeTax: true,
                    incomeTax: false,
                    gosiRate: 0,
                    salaryDetailList: [],
                },
                summary: {
                    grossSalary: 0,
                    taxableSalary: 0,
                    taxPerPeriod: 0,
                    netSalary: 0
                },
                salaryTypeOptions: [],
                salaryTaxSlab: [],
                dateRender: 0,
                periodRender: 0,
                language: 'Nothing',
            }
        },

        validations: {
            salaryTemplate:
            {
                salaryType: {
                    required
                },
                employeeId: {
                    required
                },
                payPeriodId: {
                    required
                },
                startingDate: {
                    required
                },

            },

        },

        methods: {
            GetPeriod: function () {
                //if (this.salaryTemplate.salaryType =='Hourly Based') {
                //    this.salaryTemplate.salaryDetailList = [];
                //    this.salaryTemplate.incomeTax = false;
                //    this.salaryTemplate.autoIncomeTax = true;
                //    this.salaryTemplate.salaryTemplateId = '';
                //}
                this.periodRender++;
            },
            GetpayPeriod: function (data) {
                if (data == null) {
                    this.salaryTemplate.payPeriodId = '';
                }
                else {
                    this.salaryTemplate.payPeriodId = data.id;
                }
            },

            GetTemplate: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Payroll/SalaryTemplateDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {

                            root.salaryTemplate.salaryDetailList = [];
                            root.salaryTemplate.incomeTax = false;
                            root.salaryTemplate.autoIncomeTax = false;

                            if ((root.$i18n.locale == 'en' || root.isLeftToRight())) {
                                response.data.salaryAllowances.forEach(function (result) {
                                    root.addProduct(result.id, result, 'allowance');
                                });

                                response.data.salaryDeductions.forEach(function (result) {
                                    root.addProduct(result.id, result, 'deduction');
                                });

                                response.data.salaryContributions.forEach(function (result) {
                                    root.addProduct(result.id, result, 'contribution');
                                });
                            }
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            },

            addIncomeTax: function () {
                if (this.salaryTemplate.incomeTax) {
                    this.salaryTemplate.autoIncomeTax = true;
                    var rowId = this.createUUID();
                    this.salaryTemplate.salaryDetailList.push({
                        rowId: rowId,
                        itemId: rowId,
                        type: 'Income Tax',
                        nameInPayslip: 'Income Tax',
                        nameInPayslipArabic: 'ضريبة الدخل',
                        amountType: 2,
                        taxPlan: false,
                        amountOrPercent: 0,
                        amount: 0,
                        percent: 0,
                    });

                    var item = this.salaryTemplate.salaryDetailList.find((x) => {
                        return x.rowId == rowId;
                    });
                    this.updateLineTotal(item.amountType, item);
                }
                else {
                    this.salaryTemplate.salaryDetailList = this.salaryTemplate.salaryDetailList.filter((prod) => {
                        return prod.type != 'Income Tax';
                    });
                    this.salaryTemplate.autoIncomeTax = false;
                    this.calcuateSummary();
                }


            },

            calcuateSummary: function () {
                /*Gross Salary*/
                this.summary.grossSalary = this.salaryTemplate.salaryDetailList.reduce(function (sum, record) {
                    if (record.type == 'allowance') {
                        return sum + parseFloat(record.amount);
                    }
                    else {
                        return sum
                    }
                }, 0);

                this.summary.grossSalary = parseFloat(this.summary.grossSalary + parseFloat(Math.round(this.salaryTemplate.baseSalary))).toFixed(0);

                /*Taxable Salary*/
                var taxableGrossSalary = this.salaryTemplate.salaryDetailList.reduce(function (sum, record) {
                    if (record.type == 'allowance' && record.taxPlan) {
                        return sum + parseFloat(record.amount);
                    }
                    else {
                        return sum
                    }
                }, 0);

                var nonTaxableDeductionSalary = this.salaryTemplate.salaryDetailList.reduce(function (sum, record) {
                    if (record.type == 'deduction' && !record.taxPlan) {
                        return sum + parseFloat(record.amount);
                    }
                    else {
                        return sum
                    }
                }, 0);
                this.summary.taxableSalary = parseFloat((taxableGrossSalary - nonTaxableDeductionSalary) + parseFloat(Math.round(this.salaryTemplate.baseSalary))).toFixed(0);


                
                /*Tax Per Period*/
                var incomeTax = this.salaryTemplate.salaryDetailList.find(x => x.type == 'Income Tax' && x.nameInPayslip == 'Income Tax');
                if (incomeTax != undefined) {

                    if (this.salaryTemplate.autoIncomeTax) {
                        console.log(this.salaryTaxSlab);

                        if (this.salaryTaxSlab != null && this.salaryTaxSlab.length > 0) {
                            var totalTaxableSalary = this.summary.taxableSalary * 12;

                            var slab = this.salaryTaxSlab.find(x => x.incomeFrom < totalTaxableSalary && (x.incomeTo >= totalTaxableSalary || x.incomeTo == 0));
                            if (slab != undefined) {
                                this.summary.taxPerPeriod = (((parseFloat(totalTaxableSalary) - parseFloat(slab.incomeFrom)) / 100) * parseFloat(slab.rate)) + parseFloat(slab.fixedTax);
                                this.summary.taxPerPeriod = this.summary.taxPerPeriod / 12;
                                incomeTax.amount = this.summary.taxPerPeriod;
                            }
                        }
                        else {
                            this.summary.taxPerPeriod = 0;
                        }
                    }
                    else {
                        this.summary.taxPerPeriod = this.salaryTemplate.salaryDetailList.reduce(function (sum, record) {
                            if (record.type == 'Income Tax') {
                                return sum + parseFloat(record.amount);
                            }
                            else {
                                return sum
                            }
                        }, 0);
                    }

                }
                else {
                    this.summary.taxPerPeriod = 0;
                }


                /*Net Salary*/
                var deduction = this.salaryTemplate.salaryDetailList.reduce(function (sum, record) {
                    if (record.type == 'deduction') {
                        return sum + parseFloat(record.amount);
                    }
                    else {
                        return sum
                    }
                }, 0);

                var contribution = this.salaryTemplate.salaryDetailList.reduce(function (sum, record) {
                    if (record.type == 'contribution') {
                        return sum + parseFloat(record.amount);
                    }
                    else {
                        return sum
                    }
                }, 0);
                this.summary.netSalary = parseFloat(parseFloat(this.summary.grossSalary) - parseFloat(deduction + contribution + this.summary.taxPerPeriod)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
            },

            updateBaseSalary: function () {

                var root = this;
                if (this.salaryTemplate.salaryDetailList.length > 0) {
                    this.salaryTemplate.salaryDetailList.forEach(function (result) {
                        if (result.amountType == 1) {
                            root.updateLineTable(result.percent, result, 'percent');
                        }
                        if (result.amountType == 2) {
                            root.updateLineTable(result.amount, result, 'amount');
                        }
                    });
                }
                else {
                    this.calcuateSummary();
                }
            },

            updateLineTable: function (e, item, prop) {
                
                if (prop == 'percent') {
                    item.percent = e;
                    item.amount = (Math.round(this.salaryTemplate.baseSalary) / 100) * e;
                    item.amountType = 1;
                }

                if (prop == 'amount') {
                    item.amount = Math.round(e);
                    item.percent = Math.round(this.salaryTemplate.baseSalary) == 0 ? 0 : (e / Math.round(this.salaryTemplate.baseSalary)) * 100;
                    item.amountType = 2;
                }

                this.calcuateSummary();
            },

            updateLineTotal: function (e, item) {

                if (e == 1) {
                    item.percent = item.amountOrPercent;
                    item.amount = (Math.round(this.salaryTemplate.baseSalary) / 100) * item.amountOrPercent;
                }

                if (e == 2) {
                    item.amount = item.amountOrPercent;
                    item.percent = Math.round(this.salaryTemplate.baseSalary) == 0 ? 0 : (item.amountOrPercent / Math.round(this.salaryTemplate.baseSalary)) * 100;
                }

                this.calcuateSummary();
            },

            addProduct: function (itemId, newItem, prop) {
                var rowId = '';
                var existingItem = this.salaryTemplate.salaryDetailList.find((x) => {
                    return x.itemId == itemId;
                });

                if (existingItem == undefined) {

                    if (prop == 'allowance') {
                        rowId = this.createUUID();
                        this.salaryTemplate.salaryDetailList.push({
                            rowId: rowId,
                            itemId: itemId,
                            type: prop,
                            nameInPayslip: newItem.allowanceNameEn,
                            nameInPayslipArabic: newItem.allowanceNameAr,
                            amountType: newItem.amountType,
                            taxPlan: newItem.taxPlan == 1 ? true : false,
                            amountOrPercent: newItem.amount,
                            amount: 0,
                            percent: 0,
                        });

                        var item = this.salaryTemplate.salaryDetailList.find((x) => {
                            return x.rowId == rowId;
                        });
                        this.updateLineTotal(item.amountType, item);

                    }

                    if (prop == 'deduction') {

                        rowId = this.createUUID();
                        this.salaryTemplate.salaryDetailList.push({
                            rowId: rowId,
                            itemId: itemId,
                            type: prop,
                            nameInPayslip: newItem.nameInPayslip,
                            nameInPayslipArabic: newItem.nameInPayslipArabic,
                            amountType: newItem.amountType,
                            taxPlan: newItem.taxPlan == 1 ? true : false,
                            amountOrPercent: newItem.amount,
                            amount: 0,
                            percent: 0,
                        });

                        var deduction = this.salaryTemplate.salaryDetailList.find((x) => {
                            return x.rowId == rowId;
                        });
                        this.updateLineTotal(deduction.amountType, deduction);
                    }

                    if (prop == 'contribution') {
                        rowId = this.createUUID();
                        this.salaryTemplate.salaryDetailList.push({
                            rowId: rowId,
                            itemId: itemId,
                            type: prop,
                            nameInPayslip: newItem.nameInPayslip,
                            nameInPayslipArabic: newItem.nameInPayslipArabic,
                            amountType: newItem.amountType,
                            taxPlan: false,
                            amountOrPercent: newItem.amount,
                            amount: 0,
                            percent: 0,
                        })

                        var contribution = this.salaryTemplate.salaryDetailList.find((x) => {
                            return x.rowId == rowId;
                        });
                        this.updateLineTotal(contribution.amountType, contribution);
                    }
                }

            },

            removeItem: function (id) {

                this.salaryTemplate.salaryDetailList = this.salaryTemplate.salaryDetailList.filter((prod) => {
                    return prod.rowId != id;
                });

                this.calcuateSummary();
            },

            createUUID: function () {

                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },

            GetTaxSlab: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Payroll/SalaryTaxSlabDetail', { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data != null) {
                            root.salaryTaxSlab = response.data.salaryTaxSlabList;
                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });

            },

            languageChange: function (lan) {

                if (this.language == lan) {
                    if (this.salaryTemplate.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/AddEmployeeSalary');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }
            },

            Close: function () {
                this.$router.push('/EmployeeSalary');
            },

            SaveSalaryTemplate: function () {

                this.loading = true;
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https
                    .post('/Payroll/SaveEmployeeSalary', root.salaryTemplate, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {

                        if (response.data.isSuccess) {
                            root.loading = false

                            root.$swal({
                                title: "Saved!",
                                text: "Saved Sucessfully",
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.push('/EmployeeSalary');
                                }
                            });
                        }

                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }

                    })
                    .catch(error => {
                        console.log(error)
                        this.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,

                            });

                        this.loading = false
                    })
                    .finally(() => this.loading = false)
            }
        },

        created: function () {
            this.$emit('input', this.$route.name);

            if (this.$route.query.data != undefined) {

                var data = this.$route.query.data;
                this.salaryTemplate.id = data.id;
                this.salaryTemplate.salaryType = data.salaryType;
                this.salaryTemplate.payPeriodId = data.payPeriodId;
                this.salaryTemplate.baseSalary = data.baseSalary;
                this.salaryTemplate.weekdayHourlySalary = data.weekdayHourlySalary;
                this.salaryTemplate.weekendDayHourlySalary = data.weekendDayHourlySalary;
                this.salaryTemplate.salaryTemplateId = data.salaryTemplateId;
                this.salaryTemplate.employeeId = data.employeeId;
                this.salaryTemplate.startingDate = data.startingDate;
                this.salaryTemplate.autoIncomeTax = data.autoIncomeTax;
                this.salaryTemplate.incomeTax = data.incomeTax;
                this.salaryTemplate.gosiRate = data.gosiRate;
                this.salaryTaxSlab = data.salaryTaxSlabList;

                data.salaryDetailList.forEach(function (result) {
                    result.rowId = result.id;
                });

                this.salaryTemplate.salaryDetailList = data.salaryDetailList;
                this.updateBaseSalary();
            }
            else {
                this.GetTaxSlab();
            }
        },
        mounted: function () {
            this.currency = localStorage.getItem('currency');
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.language = this.$i18n.locale;

            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.salaryTypeOptions = ['Salary Based', 'Hourly Based'];
            }
            else {
                this.salaryTypeOptions = ['على أساس الراتب', 'بالساعة'];
            }
        }
    })
</script>
<style scoped>
    .checkBoxHeight {
        width: 20px;
        height: 30px;
    }

    .input-group-append .input-group-text, .input-group-prepend .input-group-text {
        background-color: #e3ebf1;
        border: 1px solid #e3ebf1;
        color: #000000;
    }

    .input-group .form-control {
        border-left: 1px solid #e3ebf1;
    }

        .input-group .form-control:focus {
            border-left: 1px solid #3178F6;
        }

    .input-group-text {
        border-radius: 0;
    }
</style>